import { createApi } from '@reduxjs/toolkit/query/react';
import { platformAuthConfig } from './platformAuthConfig';

const userApi = createApi({
    reducerPath: 'userApi',
    ...platformAuthConfig(),
    tagTypes: ['User'],

    endpoints: builder => ({
        verifyUser: builder.mutation({
            query: () => ({
                url: '/user/verify',
                method: 'POST',
            }),
            invalidatesTags: ['User']
        }),
        createAdmin: builder.mutation({
            query: () => ({
                url: '/user/create/admin',
                method: 'PUT',
            }),
            invalidatesTags: ['User']
        }),
        createMember: builder.mutation({
            query: (config) => ({
                url: '/user/create/member',
                method: 'PUT',
                body: config
            }),
            invalidatesTags: ['User']
        }),
        deleteMember: builder.mutation({
            query: (config) => ({
                url: '/user/member',
                method: 'DELETE',
                body: config
            }),
            invalidatesTags: ['User']
        }),
        assignPermission: builder.mutation({
            query: () => ({
                url: '/user/assign',
                method: 'POST',
            })
        }),
        revokePermission: builder.mutation({
            query: () => ({
                url: '/user/revoke',
                method: 'POST',
            })
        }),
        getUserPermissions: builder.query({
            query: (email) => ({
                url: `/user/permissions?member_email=${email}`,
                method: 'GET',
            }),
            providesTags: ['User']
        }),
        updatePermission: builder.mutation({
            query: (config) => ({
                url: '/user/permissions',
                method: 'POST',
                body: config
            }),
            invalidatesTags: ['User']
        }),
    })
});

export const {
    useVerifyUserMutation,
    useCreateAdminMutation,
    useCreateMemberMutation,
    useDeleteMemberMutation,
    useAssignPermissionMutation,
    useRevokePermissionMutation,
    useGetUserPermissionsQuery,
    useUpdatePermissionMutation,
} = userApi;

export default userApi;

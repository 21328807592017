import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import AddPathModal from '../Components/StoragePathCompnents/AddPathsModal';
import PathList from '../Components/StoragePathCompnents/PathList';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { openStorageModal } from '../DataStore/modalSlice';
import { useDeleteArtifactStoragePathMutation, useGetArtifactStoragePathsQuery } from '../Services/artifactStorageApi';


const ArtifactStoragesView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetArtifactStoragePathsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteArtifactStoragePathMutation()

  if (isLoading || isDeleting) {
    return <Loader />
  }

  return (
    <Stack>
      <Stack spacing={2}>
        <Typography fontFamily="IBMPlexSansBold" fontSize="25px" pb={2}>Artifacts Storages</Typography>
        <Stack alignItems="end">
          <CustomButton width="30%" onClick={() => dispatch(openStorageModal())}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            ADD ARTIFACTS STORAGE
          </CustomButton>
        </Stack>
        <PathList data={data} handleDelete={handleDelete} />
      </Stack>
      <AddPathModal />
    </Stack >
  )
}

export default ArtifactStoragesView
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AddMemberModal from '../Components/TeamViewComponents/AddMemberModal'
import Header from '../Components/TeamViewComponents/Header'
import TeamList from '../Components/TeamViewComponents/TeamList'
import { useDeleteMemberMutation } from '../Services/userApi'
import LoadingView from './LoadingView'

const TeamView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const user_metadata = useSelector(store => store?.userState?.sgUser?.user_metadata)

  const [deleteMember, { isLoading: isDeleting }] = useDeleteMemberMutation()

  const onDelete = (email) => {
    deleteMember({ "email": email, "productType": "SCALEGEN" })
  }

  if (isDeleting) {
    <LoadingView />
  }

  return (
    <Stack gap={2}>
      <Header handleAdd={() => setIsModalOpen(true)} />
      <TeamList data={user_metadata?.member_users} handleDelete={onDelete} />
      <AddMemberModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} />
    </Stack>
  )
}

export default TeamView
import ClearIcon from '@mui/icons-material/Clear';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateMemberMutation } from '../../Services/userApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import Loader from '../UiComponents/Loader';


const AddMemberModal = ({ isOpen, handleClose }) => {


  const [email, setEmail] = useState("")

  const [handleSubmit, { isLoading, isSuccess }] = useCreateMemberMutation();

  useEffect(() => {
    if (isSuccess) {
      setEmail("")
      handleClose()
    }
  }, [isSuccess, handleClose])

  const handleAdd = () => {
    handleSubmit({
      email,
      productType: "SCALEGEN"
    })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <PersonRoundedIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add Member</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "35vh",
        width: "40vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        <Stack gap={2}>
          <Stack gap={1} >
            <Typography fontSize="15px">User Email</Typography>
            <InputField state={email}
              placeholder="Enter user's email address"
              setState={(e) => setEmail(e.target.value)}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={handleAdd}
          width="10%"
        >
          Add
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddMemberModal
import { configureStore } from '@reduxjs/toolkit'

// Redux slice
import errorStateReducer from './errorSlice'
import modalStateReducer from './modalSlice'
import newFineTuneStateReducer from "./newFineTune"
import newInferenceStateReducer from './newInferenceSlice'
import newJobStateReducer from './newJobSlice'
import userStateReducer from './userSlice'

// Api slices
import { ErrorMiddleware } from '../Errors/ErrorMiddleware'
import accessKeysApi from '../Services/accessKeysApi'
import artifactStorageApi from '../Services/artifactStorageApi'
import artifactsApi from '../Services/artifactsApi'
import cloudProviderApi from '../Services/cloudProviderApi'
import fineTuneApi from '../Services/fineTuneApi'
import gpuMachinesApi from '../Services/gpuMachinesApi'
import inferenceApi from '../Services/inferenceApi'
import jobsApi from '../Services/jobsApi'
import liveLogsApi from '../Services/livelogsApi'
import llmGatewayApi from '../Services/llmgatewayApi'
import logsApi from '../Services/logsApi'
import nodeApi from '../Services/nodeApi'
import secretsApi from '../Services/secretsApi'
import templatesApi from '../Services/templatesApi'
import trialApi from '../Services/trialsApi'
import userApi from '../Services/userApi'
import virtualMountsApi from '../Services/virtualMountsApi'

export const store = configureStore({
  reducer: {
    [userStateReducer.name]: userStateReducer.reducer,
    modal: modalStateReducer.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [cloudProviderApi.reducerPath]: cloudProviderApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [virtualMountsApi.reducerPath]: virtualMountsApi.reducer,
    [artifactStorageApi.reducerPath]: artifactStorageApi.reducer,
    newJobData: newJobStateReducer.reducer,
    [inferenceApi.reducerPath]: inferenceApi.reducer,
    [fineTuneApi.reducerPath]: fineTuneApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [trialApi.reducerPath]: trialApi.reducer,
    [artifactsApi.reducerPath]: artifactsApi.reducer,
    [liveLogsApi.reducerPath]: liveLogsApi.reducer,
    [accessKeysApi.reducerPath]: accessKeysApi.reducer,
    [secretsApi.reducerPath]: secretsApi.reducer,
    [nodeApi.reducerPath]: nodeApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [gpuMachinesApi.reducerPath]: gpuMachinesApi.reducer,
    [llmGatewayApi.reducerPath]: llmGatewayApi.reducer,
    [newInferenceStateReducer.name]: newInferenceStateReducer.reducer,
    [newFineTuneStateReducer.name]: newFineTuneStateReducer.reducer,
    [errorStateReducer.name]: errorStateReducer.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      cloudProviderApi.middleware,
      jobsApi.middleware,
      virtualMountsApi.middleware,
      artifactStorageApi.middleware,
      inferenceApi.middleware,
      fineTuneApi.middleware,
      logsApi.middleware,
      trialApi.middleware,
      artifactsApi.middleware,
      liveLogsApi.middleware,
      accessKeysApi.middleware,
      secretsApi.middleware,
      nodeApi.middleware,
      templatesApi.middleware,
      llmGatewayApi.middleware,
      gpuMachinesApi.middleware,
      ErrorMiddleware
    )
})

//define routes here before use to avoid spelling issues

export const PAGE_ROUTES = Object.freeze({
  login: "/login",
  dashboard: "/",
  cloudProviders: "/admin/cloud-providers",
  virtualMounts: "/admin/virtual-mounts",
  artifactStorages: "/admin/artifact-storages",
  secrets: "/admin/secrets",
  setting: "/settings",
  training: '/training',
  createJob: '/training/new/job',
  relaunchJob: '/training/relaunch/job',
  relaunchFinetune: '/training/relaunch/finetuning',
  team: '/members',
  inferences: '/inference',
  newInference: '/new/inference',
  finetuning: '/training/new/finetuning',
  finetuningLlamaMistral: '/training/new/finetuning/llama-mistral',
  job: '/:type/:id',
  onPremNodes: "/admin/on-prem-nodes",
  templates: '/templates',
  template: "/template/:id",
  inference: '/inference/:id',
  newEmbeddingsInference: '/new/embeddings/inference',
  notFoundError: '/not-found',
  llmGateways: '/llm/gateway',
  llmGateway: '/llm/gateway/:id',
  newLLMGateway: '/new/llm/gateway',
  llmProviders: '/admin/llm/providers',
  machines: "/gpu-machine",
  machine: "/gpu-machine/:id"
})
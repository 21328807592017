import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import App from './App';
import './Assets/Fonts/ibmPlexFontStyle.css';
import { store } from './DataStore/store';
import { theme } from "./Styles/Theme";
import { ErrorFallbackPage } from "./Views/ErrorFallbackPage";
import './index.css';

const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_SENTRY_DSN,
} = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallbackPage />}>
      <Provider store={store}>
        <Auth0Provider
          domain={REACT_APP_AUTH_DOMAIN}
          clientId={REACT_APP_AUTH_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: REACT_APP_AUTH0_AUDIENCE,
          }}
          cacheLocation="localstorage"
        >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Auth0Provider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

